import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{color: "#FFFFFF"}}>404: Not Found</h1>
  </Layout>
)

export default NotFoundPage
